import { useRouter } from "next/router";
import { useState } from "react";
import FormInput from "../ui/FormInput";

/**
 * Used on the Login and Sign Up screens to handle authentication. Can be shared between those as Passport.js doesn't differentiate between logging in and signing up.
 */
export default function AuthenticationForm({
  buttonText,
}: {
  buttonText?: string;
}) {
  const [email, setEmail] = useState("");
  const router = useRouter();
  const { r } = router.query;
  const redirect = r?.toString();

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md px-2">
      <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            // POST a request with the users email or phone number to the server
            fetch(`/api/auth/magiclink`, {
              method: `POST`,
              body: JSON.stringify({
                redirect,
                destination: email,
              }),
              headers: { "Content-Type": "application/json" },
            })
              .then((res) => res.json())
              .then((json) => {
                if (json.success) {
                  // Add the email and security code to the query params so we can show them on the /check-mailbox page
                  router.push(
                    `/check-mailbox?e=${encodeURIComponent(email)}&c=${
                      json.code
                    }`
                  );
                }
              });
          }}
        >
          <FormInput
            name="email"
            type="email"
            autoComplete="email"
            label="Email"
            helpText="Vi sender dig et link, der logger dig direkte ind."
            onChange={(evt) => setEmail(evt.target.value)}
            value={email}
          />
          <button
            type="submit"
            disabled={!email}
            className="w-full flex justify-center mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white disabled:bg-gray-300 disabled:hover:opacity-100 bg-mm-purple hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mm-purple"
          >
            {buttonText || "Log ind"}
          </button>
        </form>
      </div>
    </div>
  );
}
