import AuthenticationForm from "../client/components/AuthenticationForm";

function Login() {
  return (
    <>
      <div className="bg-mm-light-rose h-screen overflow-hidden flex flex-col justify-center">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src="/images/mm-logo.png"
            alt="Min Mave"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-mm-purple">
            Log ind
          </h2>
          <p className="mt-4 text-center text-base font-medium leading-normal text-black px-2">
            Indtast din email nedenfor. Du modtager med det samme et link, som
            logger dig direkte ind på din konto.
          </p>
        </div>

        <AuthenticationForm buttonText="Log ind" />
      </div>
    </>
  );
}

export default Login;
